import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import MonitoringNetworkComponent from "../components/monitoring-networks/monitoring-networks.component"

const IndexPage = () => {
  return (
    <Layout>
      <MonitoringNetworkComponent />
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
